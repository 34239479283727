import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
  Text,
  Flex,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import { removeHTMLAndSpaces, sanitizeData } from '../../utils/helpers';
import { useCreateModerationReportMutation } from '../../services/apis/postsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';

const AppealModal = ({
  content_type = 'post',
  content_id,
  appealed_item = '',
}) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [appealComment, setAppealComment] = useState('');
  const [appealCommentLength, setAppealCommentLength] = useState(0);
  const [createReport,
    {
      isLoading: isCreatingAppealReport,
      isError,
      error,
    },
  ] = useCreateModerationReportMutation();

  const isButtonDisabled = !removeHTMLAndSpaces(appealComment) || isCreatingAppealReport
  || appealCommentLength > 560;

  const handleAppeal = async () => {
    const reportBodyObj = {
      report: {
        reason: sanitizeData('appeal'),
        report_comment: sanitizeData(appealComment),
      },
      object_type: content_type,
      object_id: content_id,
    };

    try {
      await createReport(reportBodyObj).unwrap();
      onClose();
    } catch (err) {
      console.error('Failed to appeal', err);
    }
  };

  const handleAppealModalOpen = (event) => {
    event.stopPropagation();
    onOpen();
  };

  const handleAppealClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <Button
        onClick={handleAppealModalOpen}
        onKeyDown={handleAppealClick}
        colorScheme="blue"
        variant="outline"
        bg="white"
        width="120px"
        style={{
          ...theme.styles.global.button,
          fontWeight: '600',
        }}
        size="sm"
      >
        Appeal
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent
          borderTop="8px solid"
          borderColor="blue.600"
          style={{ ...theme.styles.components.modalStyle }}
        >
          <ModalHeader>
            <Text style={{ ...theme.styles.global.h3, fontSize: '24px' }}>
              Appeal to Moderators
            </Text>
            <Flex flexDirection="column">
              <Text
                variant="italic"
                style={{ textAlign: 'left', fontSize: '14px' }}
                color="black"
                mt={2}
              >
                This request will be seen by the admins and moderators.
                They will choose whether to take action, an appeal is not a guarantee
                action will be taken. Please be as clear and concise as possible.
              </Text>
              <Text
                variant="italic"
                style={{ textAlign: 'left', fontSize: '14px' }}
                color="black"
                mt={2}
              >
                Once you have appealed a decision, do not appeal it again.
                Do not misuse the appeal function, or you may lose additional
                privileges on the Community.
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text style={{ ...theme.styles.global.h4 }} mt={4}>
              Item Being Appealed
            </Text>
            <Text style={{ ...theme.styles.global.body, fontSize: '14px', fontWeight: '700' }} mb={2} color="blue.500">
              {parse(appealed_item)}
            </Text>
            <Text style={{ ...theme.styles.global.h4 }} mb={2} mt={8}>
              Explain Why This Moderation Action Should be Changed:
            </Text>
            <QuillTextEditor
              quillText={appealComment}
              setQuillText={setAppealComment}
              customStyle={{ margin: 0 }}
              toolBarType="2"
              setQuillCharCount={setAppealCommentLength}
              quillCharCount={appealCommentLength}
            />
          </ModalBody>
          <ModalFooter justifyContent="flex-start" mt="2rem" width="100%">
            <Button
              className="change-focus"
              mr={3}
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              _hover={{ opacity: 0.9 }}
              color="blue.600"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              bg="blue.600"
              onClick={handleAppeal}
              size="sm"
              isDisabled={isButtonDisabled}
            >
              Submit
            </Button>
          </ModalFooter>
          {
            isCreatingAppealReport && (
              <Flex style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="loading" alert_title="Creating Appeal Report..." />
              </Flex>
            )
        }
          {
            isError && (
              <Flex style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={error} />
              </Flex>
            )
          }
        </ModalContent>
      </Modal>
    </>
  );
};
export default AppealModal;
